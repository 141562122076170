<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-custom gutter-b">
        <div class="card-header card-header-tabs-line">
          <div class="card-title">
            <h1 class="card-label">{{ $t('REISETERMINE.RELEASEPROZESS.TITLE') }}</h1>
          </div>
          <div class="card-toolbar d-flex text-align-center">
            <SavedContent :isSaved="isSaved"></SavedContent>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 d-flex align-items-center">
              <b-form-group
                v-if="processObj"
                :disabled="!isReiseterminInReleasePruefung"
                class="flex-fill mb-0"
              >
                <h6>{{ $t('REISETERMINE.RELEASEPROZESS.STEP1TITLE') }}</h6>
                <p>
                  {{ $t('REISETERMINE.RELEASEPROZESS.STEP1TEXT1') }}
                </p>
                <div class="row">
                  <div class="col-md-2">Hotels</div>
                  <b-form-radio-group v-model="processObj.dmcHotels" @input="onChange" class="col-md-10">
                    <b-form-radio :value="true">Ja</b-form-radio>
                    <b-form-radio :value="false">Nein</b-form-radio>
                  </b-form-radio-group>
                  <div class="col-md-2">Reiseleiter</div>
                  <b-form-radio-group v-model="processObj.dmcReiseleiter" @input="onChange" class="col-md-10">
                    <b-form-radio :value="true">Ja</b-form-radio>
                    <b-form-radio :value="false">Nein</b-form-radio>
                  </b-form-radio-group>
                </div>

                <h6 class="mt-8">{{ $t('REISETERMINE.RELEASEPROZESS.STEP2TITLE') }}</h6>
                <p>
                  {{ $t('REISETERMINE.RELEASEPROZESS.STEP2TEXT1') }}
                  <span title="Reisehinweise">
                    <a
                      class="bg-primary p-1 rounded"
                      :href="
                        'https://www.skr.de/reiseinfos/' +
                        reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
                        '/L/'
                      "
                      target="_blank"
                    >
                      <i class="flaticon-book text-white text-hover-primary font-weight-bold"></i>
                    </a>
                  </span>
                </p>

                <div class="mt-3 text-dark-75 font-weight-bold">
                  {{ $t('REISETERMINE.RELEASEPROZESS.STEP2TEXT2') }}
                </div>
                <b-form-checkbox v-model="processObj.reiseunterlagenGeprueft" @input="onChange">
                  <div class="border rounded px-1">
                    {{ $t('REISETERMINE.RELEASEPROZESS.STEP2TEXT3') }}
                    <span class="text-danger font-size-h5" v-b-tooltip.hover title="Pflichtfeld">*</span>
                  </div>
                </b-form-checkbox>
              </b-form-group>
              <template v-else-if="!isLoading">
                <div class="p-5 bg-secondary rounded w-100">
                  {{ $t('REISETERMINE.RELEASEPROZESS.STEP2NODATAFOUND') }}
                </div>
              </template>
              <template v-else>
                <b-skeleton width="100%" height="50px"></b-skeleton>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-custom">
        <div class="card-header card-header-tabs-line">
          <div class="card-title">
            <h1 class="card-label">{{ $t('REISETERMINE.RELEASEPROZESS.TRANSFERPLANUNG.TITLE') }}</h1>
          </div>
          <div class="card-toolbar d-flex text-align-center">
            <SavedContent :isSaved="isTransferplanungSaved"></SavedContent>
          </div>
        </div>
        <div class="card-body">
          <b-form-group v-if="processObj" :disabled="!isReiseterminInReleasePruefung" class="flex-fill mb-0">
            <p>
              {{ $t('REISETERMINE.RELEASEPROZESS.TRANSFERPLANUNG.TEXT1') }}
            </p>
            <b-form-checkbox v-model="processObj.transferplanungGeprueft" @input="onChange">
              <div
                class="border rounded px-1"
                v-b-tooltip.hover
                title="Nur im 3. Part der Transferplanung setzbar."
              >
                {{ $t('REISETERMINE.RELEASEPROZESS.TRANSFERPLANUNG.DONE') }}
                <span class="text-danger font-size-h5" v-b-tooltip.hover title="Pflichtfeld">*</span>
              </div>
            </b-form-checkbox>
          </b-form-group>
          <template v-else-if="!isLoading">
            <div class="p-5 bg-secondary rounded w-100">
              {{ $t('REISETERMINE.RELEASEPROZESS.TRANSFERPLANUNG.NODATA') }}
            </div>
          </template>
          <template v-else>
            <b-skeleton width="100%" height="50px"></b-skeleton>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/core/common/services/api.service';
import * as odataService from '@/core/common/services/odata.service';
import SavedContent from '@/components/common/saved-content.vue';

export default {
  name: 'ReiseterminCardReleaseProcess',
  components: {
    SavedContent,
  },
  props: {
    reiseterminkuerzel: String,
    reiseterminId: Number,
    isReiseterminInReleasePruefung: { type: Boolean, default: false },
    isInTransferplanung: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      isSaved: true,
      isTransferplanungSaved: true,
      formInputDebounceValue: 300,
      debounceTimeoutId: null,
      processObj: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    const processes = await this.getCurrentProcess();
    this.processObj = processes;
  },
  methods: {
    saveProcess() {
      console.log(this.processObj);
      apiService
        .put('Reisetermin/' + this.reiseterminId + '/releaseprozess', {
          id: this.reiseterminId,
          ...this.processObj,
        })
        .then(() => {
          this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          this.$emit('releaseprocess-updated');
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isSaved = true;
          this.isLoading = false;
        });
    },
    onChange() {
      this.isSaved = false;
      this.isLoading = true;
      this.saveProcess();
    },
    async getCurrentProcess() {
      const filter = { id: this.reiseterminId };

      return odataService
        .getReisetermin({
          filter,
          expand: {
            hotelleistungen: {
              expand: {
                vorgang: {},
              },
            },
            Reise: {},
            Metadata: {
              expand: {
                ZiellandZielflughaefen: {},
                ZiellandAbflughaefen: {},
                Inlandsfluege: {},
              },
            },
            gueltigeKonfiguration: {},
            pax: {},
            comments: {},
          },
        })
        .then(response => {
          const { dmcHotels, dmcReiseleiter, reiseunterlagenGeprueft, transferplanungGeprueft } =
            response.data[0];
          return {
            dmcHotels,
            dmcReiseleiter,
            reiseunterlagenGeprueft,
            transferplanungGeprueft,
          };
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
