<template>
  <div>
    <VorgangTable
      v-if="vorgaenge"
      :displayFieldsByKey="['id', 'kunde', 'npx', 'releaseprocessActions1', 'releaseProzessState']"
      :isLoading="isLoading"
      :items="vorgaenge"
      :rowSelectBlockExpression="rowSelectBlockExpression"
      :dokumenteCheckRequired="dokumenteCheckRequired"
      :passdatenCheckRequired="passdatenCheckRequired"
      releaseprocessActions1Label="Reiseleistungen & Listenversand"
    >
      <template v-slot:releaseprocessActions1Head="data">
        <div class="text-nowrap">
          {{ selected.length === 0 ? 'Alle Zeilen:' : 'Ausgewählte Zeilen:' }}
          <b-form-select
            size="sm"
            class="mb-1 col-md-8"
            v-model="allRowsListenversand"
            :disabled="!isEveryVorgangBuchungAndNotReleased"
            @change="onChangeMultipleListenversand"
          >
            <b-form-select-option :value="null" disabled> Alle Zeilen anpassen </b-form-select-option>
            <b-form-select-option :value="'NichtGeprueft'">{{
              $t('REISETERMINE.RELEASEPROZESS.NICHTGEPRUEFT')
            }}</b-form-select-option>
            <b-form-select-option :value="'GeprueftVorhandenKorrekt'">{{
              $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTKORREKT')
            }}</b-form-select-option>
            <b-form-select-option :value="'GeprueftNichtKorrektInArbeit'">{{
              $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTINARBEIT')
            }}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="text-nowrap">Reiseleistungen & Listenversand</div>
      </template>
      <template v-slot:releaseprocessActions1="data">
        <b-form-select
          :disabled="!isReiseterminInReleasePruefung || !(data.isVorgangBuchung || data.isStornierteBuchung)"
          size="sm"
          v-model="data.vorgang.gebuchteReiseleistungListenversandGeprueft"
          @change="onChangeListenversand(data)"
        >
          <b-form-select-option :value="'NichtGeprueft'">{{
            $t('REISETERMINE.RELEASEPROZESS.NICHTGEPRUEFT')
          }}</b-form-select-option>
          <b-form-select-option :value="'GeprueftNichtKorrektInArbeit'">{{
            $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTINARBEIT')
          }}</b-form-select-option>
          <b-form-select-option :value="'GeprueftVorhandenKorrekt'">{{
            $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTKORREKT')
          }}</b-form-select-option>
        </b-form-select>
      </template>
    </VorgangTable>
  </div>
</template>

<script>
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import { UPDATE_VORGANG_RELEASEPROZESS } from '../../../core/vorgaenge/stores/vorgaenge.module';

export default {
  components: { VorgangTable },
  props: {
    isLoading: { type: Boolean },
    reiseterminId: { type: Number },
    passdatenCheckRequired: { type: Boolean },
    isReiseterminInReleasePruefung: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
    vorgaenge: { type: Array },
    reiseterminObj: { type: Object },
  },
  data() {
    return {
      selected: [],
      deselectRow: null,
      allRowsListenversand: null,
    };
  },
  computed: {
    isEveryVorgangBuchungAndNotReleased() {
      return (
        this.isReiseterminInReleasePruefung &&
        (this.selected.every(
          vorgang => vorgang?.blankState === 'Buchung' || vorgang?.blankState === 'StornierteBuchung'
        ) ||
          this.vorgaenge.every(
            vorgang => vorgang?.blankState === 'Buchung' || vorgang?.blankState === 'StornierteBuchung'
          ))
      );
    },
  },
  methods: {
    rowSelectBlockExpression(item) {
      return (
        this.isReiseterminInReleasePruefung &&
        (item === 'Buchung' || item === 'StornierteBuchung') &&
        !item.isReleased
      );
    },
    onChangeListenversand(data) {
      const { vorgang } = data;
      const {
        id,
        gebuchteReiseleistungListenversandGeprueft,
        dokumenteGeprueft,
        passdatenGeprueft,
        teilnehmerGeprueft,
        fluegePassend,
        transferplanungErledigt,
        transfergruppe,
      } = vorgang;
      const params = [
        {
          id,
          gebuchteReiseleistungListenversandGeprueft,
          dokumenteGeprueft,
          passdatenGeprueft,
          teilnehmerGeprueft,
          fluegePassend,
          transferplanungErledigt,
          transfergruppe,
        },
      ];
      this.$store.dispatch(UPDATE_VORGANG_RELEASEPROZESS, params).then(() => {
        this.toast('Gespeichert.');
        this.$emit('workflow-updated');
      });
    },
    onChangeMultipleListenversand() {
      const params = [];
      for (const vorgang of this.vorgaenge) {
        const {
          id,
          dokumenteGeprueft,
          passdatenGeprueft,
          teilnehmerGeprueft,
          fluegePassend,
          transferplanungErledigt,
          transfergruppe,
        } = vorgang;
        params.push({
          id,
          gebuchteReiseleistungListenversandGeprueft: this.allRowsListenversand,
          dokumenteGeprueft,
          passdatenGeprueft,
          teilnehmerGeprueft,
          fluegePassend,
          transferplanungErledigt,
          transfergruppe,
        });
      }
      this.$store.dispatch(UPDATE_VORGANG_RELEASEPROZESS, params).then(() => {
        this.toast('Alle Zeilen wurden gespeichert.');
        this.$emit('workflow-updated');
        this.vorgaenge.map(
          vorgang => (vorgang.gebuchteReiseleistungListenversandGeprueft = this.allRowsListenversand)
        );
      });
    },
  },
};
</script>
