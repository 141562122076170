<template>
  <span class="d-flex align-items-center" v-if="showInitial">
    <i v-if="isSaved" class="ki ki-check icon-xs text-success ml-2 mr-2"></i>
    <i v-else class="fas fa-clock icon-xs ml-2 mr-2"></i>
    <span class="text-muted">
      {{ savedText }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'SavedContent',
  props: { isSaved: { type: Boolean, default: false } },
  data() {
    return {
      showSaved: true,
      showInitial: false,
    };
  },
  watch: {
    isSaved() {
      this.showSaved = true;
      this.showInitial = true;
      setTimeout(() => {
        this.showSaved = false;
      }, 1500);
    },
  },
  computed: {
    savedText() {
      return this.isSaved ? (this.showSaved ? this.$t('COMMON.SAVED') : '') : this.$t('COMMON.SAVING');
    },
  },
};
</script>
