<template>
  <VorgangTable
    v-if="vorgaenge"
    :displayFieldsByKey="[
      'id',
      'pnr',
      'airlinePNR',
      'reisezeitraum',
      'kunde',
      'teilnehmercell',
      'flugticketsAusgestellt',
      'ticketingTimelimit',
      'npx',
      'releaseprocessActions1',
      'releaseprocessActions2',
      'releaseprocessActions3',
      'releaseProzessState',
    ]"
    :items="vorgaenge"
    :isOutlined="true"
    :isLoading="isLoading"
    :dokumenteCheckRequired="dokumenteCheckRequired"
    :passdatenCheckRequired="passdatenCheckRequired"
    releaseprocessActions1Label="Flüge passend"
    releaseprocessActions2Label="Transfergruppe"
    releaseprocessActions3Label="TRF-Planung"
    @update-single-item="$emit('workflow-updated')"
  >
    <template v-slot:releaseprocessActions1="data">
      <span>
        <b-form-checkbox
          v-show="data.vorgang.anreiseart !== 'KeinFlug' && data.vorgang.anreiseart !== 'Eigenanreise'"
          :disabled="
            !isReiseterminInReleasePruefung ||
            !(data.isVorgangBuchung || data.isStornierteBuchung) ||
            data.vorgang.anreiseart === 'KeinFlug' ||
            data.vorgang.anreiseart === 'Eigenanreise'
          "
          v-model="data.vorgang.fluegePassend"
          @input="onChangeFluegePassend(data)"
        >
          Flüge passend
        </b-form-checkbox>
      </span>
    </template>

    <template v-slot:releaseprocessActions2="data">
      <span>
        <b-form-input
          :disabled="isTransferplanungEnabled(data)"
          size="sm"
          debounce="2000"
          v-model="data.vorgang.transfergruppe"
          placeholder="Transfergruppe"
          @update="onChangeTransferplanung(data)"
        ></b-form-input>
      </span>
    </template>

    <template v-slot:releaseprocessActions3="data">
      <div style="width: 120px">
        <div v-if="keinTransferBenoetigt || dachReise">Nicht notwendig</div>
        <b-form-select
          v-else
          :disabled="isTransferplanungEnabled(data)"
          size="sm"
          v-model="data.vorgang.transferplanungErledigt"
          @change="onChangeTransferplanung(data)"
        >
          <b-form-select-option :value="'NichtGeplant'">Nicht geplant</b-form-select-option>
          <b-form-select-option :value="'Geplant'">Geplant</b-form-select-option>
          <b-form-select-option :value="'Storniert'">Storniert</b-form-select-option>
          <b-form-select-option :value="'NichtNotwendig'">Nicht notwendig</b-form-select-option>
        </b-form-select>
      </div>
    </template>

    <template v-slot:vorgangDetails="data">
      <b-button
        @click="onClickFlugChanged($event, data)"
        size="sm"
        variant="warning"
        class="text-white m-1"
        :id="'vorgang' + data.vorgangId"
        v-if="!data.isFlightsEqual"
      >
        Flüge geändert
        <i class="flaticon2-exclamation text-white"></i>
      </b-button>
      <b-tooltip v-if="!data.isFlightsEqual" :target="'vorgang' + data.vorgangId">
        <div
          class="mb-3"
          v-for="item in flightChanges(data.vorgangId)"
          :key="'flightchange' + item.flugstrecke + '-' + data.vorgangId"
        >
          <div v-for="(value, key) in item" :key="key + item.flugstrecke">
            <b v-if="key !== 'fluganzahl'" class="text-capitalize">{{ key }}:</b>
            <br />
            {{ value }}
          </div>
        </div>
      </b-tooltip>
      <div
        :class="[!data.isFlightsEqual ? 'bg-warning-o-30' : 'bg-gray-100']"
        class="border-bottom border-dark"
      >
        <b-table
          small
          fixed
          table-class="table table-standard mb-0"
          thead-tr-class="th-no-padding-top"
          no-local-sorting
          id="vorgaengeDetailsTableFluege"
          v-if="data.fluege && data.fluege.length > 0"
          :fields="['Flugstrecke', 'flugstamm', 'flugnummer', 'abflugdatum', 'abflugzeit', 'ankunftzeit']"
          :items="data.fluege.filter(flug => flug.flugnummer !== '--- -----')"
        >
          <template #cell(Flugstrecke)="row">
            {{
              row.item.teilnehmerFluegeMatchNumbers
                ? `(${row.item.teilnehmerFluegeMatchNumbers.join(',')}) `
                : null
            }}
            {{ row.item.abflughafenEigen ? row.item.abflughafenEigen : row.item.abflughafen }} -
            {{
              row.item.ankunftsflughafenEigen ? row.item.ankunftsflughafenEigen : row.item.ankunftsflughafen
            }}
          </template>
          <template #cell(flugnummer)="row">{{ row.item.flugnummer }}</template>

          <template #cell(abflugdatum)="row">{{ row.item.abflugdatum | date }}</template>
          <template #cell(abflugzeit)="row">
            {{
              row.item.abflugsZeit
                ? row.item.abflugsZeit + ' Uhr'
                : $options.filters.time(row.item.abflugdatum)
            }}
          </template>
          <template #cell(ankunftzeit)="row">
            {{
              row.item.ankunftsZeit
                ? row.item.ankunftsZeit + ' Uhr'
                : $options.filters.time(row.item.ankunftsdatum)
            }}
            <span v-if="row.item.ankunftsdatum && row.item.abflugdatum">
              {{ isArrivalTomorrow(row.item) ? ' +1' : '' }}
            </span>
          </template>
        </b-table>
      </div>
    </template>
  </VorgangTable>
</template>

<script>
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import { format, parseISO, isSameDay } from 'date-fns';

import { UPDATE_VORGANG_RELEASEPROZESS } from '@/core/vorgaenge/stores/vorgaenge.module';

import { mapGetters } from 'vuex';

export default {
  components: { VorgangTable },
  props: {
    isLoading: { type: Boolean },
    reiseterminId: { type: Number },
    state: { type: String },
    vorgaenge: { type: Array },
    isInTransferplanung: { type: Boolean, default: false },
    isReiseterminInReleasePruefung: { type: Boolean, default: false },
    passdatenCheckRequired: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
    reiseterminObj: { type: Object },
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    keinTransferBenoetigt() {
      if (this.reiseterminObj) {
        const transferszenarioOhneTransfers = ['T3_unbegr_inkl', 'TX_ohne'];
        return transferszenarioOhneTransfers.includes(this.reiseterminObj.metadata.transferszenario);
      }
      return false;
    },
    dachReise() {
      if (this.reiseterminObj) {
        return this.reiseterminObj.reise.region === 'Dach';
      } else return false;
    },
  },
  methods: {
    isArrivalTomorrow(item) {
      const departure = parseISO(item.abflugdatum);
      const arrival = parseISO(item.ankunftsdatum);
      return !isSameDay(arrival, departure);
    },
    isTransferplanungEnabled(data) {
      return !(
        (this.isInTransferplanung || this.isReiseterminInReleasePruefung) &&
        (data.isVorgangBuchung || data.isStornierteBuchung)
      );
    },
    onChangeFluegePassend(data) {
      const { vorgang } = data;
      const {
        id,
        gebuchteReiseleistungListenversandGeprueft,
        dokumenteGeprueft,
        passdatenGeprueft,
        teilnehmerGeprueft,
        fluegePassend,
        transferplanungErledigt,
        transfergruppe,
      } = vorgang;
      const params = [
        {
          id,
          gebuchteReiseleistungListenversandGeprueft,
          dokumenteGeprueft,
          passdatenGeprueft,
          teilnehmerGeprueft,
          fluegePassend,
          transferplanungErledigt,
          transfergruppe,
        },
      ];
      this.$store.dispatch(UPDATE_VORGANG_RELEASEPROZESS, params).then(() => {
        this.toast('Gespeichert.');
        this.$emit('workflow-updated');
      });
    },
    onChangeTransferplanung(data) {
      const { vorgang } = data;
      const {
        id,
        gebuchteReiseleistungListenversandGeprueft,
        dokumenteGeprueft,
        passdatenGeprueft,
        teilnehmerGeprueft,
        fluegePassend,
        transferplanungErledigt,
        transfergruppe,
      } = vorgang;
      const params = [
        {
          id,
          gebuchteReiseleistungListenversandGeprueft,
          dokumenteGeprueft,
          passdatenGeprueft,
          teilnehmerGeprueft,
          fluegePassend,
          transferplanungErledigt,
          transfergruppe,
        },
      ];

      this.$store.dispatch(UPDATE_VORGANG_RELEASEPROZESS, params).then(() => {
        this.toast('Gespeichert.');
        this.$emit('workflow-updated');
      });
    },
    async onClickFlugChanged($event, flugData) {
      const msgBoxConfirm = await this.$bvModal.msgBoxConfirm(
        this.$t('REISETERMINE.RELEASEPROZESS.AENDERUNGENZURKENTNISSGENOMMEN'),
        {
          centered: true,
          okTitle: 'OK',
          cancelTitle: 'Abbrechen',
        }
      );

      if (msgBoxConfirm) {
        this.$emit('fluege-change-accepted', { ...$event, flugData: flugData });
      }
    },
    flightChanges(vorgangId) {
      const allFlightsOfVorgang = this.vorgaenge.find(vorgang => vorgang.id === vorgangId)?.allFlights;
      const flightsNew = allFlightsOfVorgang.fluege;
      const flightsOld = allFlightsOfVorgang.fluegeAfterBuchungReleased;
      const keysToCompare = [
        'flugstamm',
        'abflughafen',
        'ankunftsflughafen',
        'flugart',
        'abflugdatum',
        'ankunftsdatum',
      ];
      const differencesInSameFlightNumbers = flightsNew
        .map(flightNew => {
          const sameFlight = flightsOld.find(flightOld => flightOld.flugnummer === flightNew.flugnummer);
          if (sameFlight) {
            const differences = {
              flugstrecke: flightNew.abflughafen + ' - ' + flightNew.ankunftsflughafen,
            };
            if (flightNew.abflughafen === null || flightNew.ankunftsflughafen === null) return null;
            for (const key of keysToCompare) {
              if (flightNew[key] !== sameFlight[key]) {
                if (key.includes('datum')) {
                  differences[key] =
                    (sameFlight[key] === null ? '-' : format(new Date(sameFlight[key]), 'dd.MM HH:mm')) +
                    ' -> ' +
                    (flightNew[key] === null ? '-' : format(new Date(flightNew[key]), 'dd.MM HH:mm'));
                } else differences[key] = sameFlight[key] + ' -> ' + flightNew[key];
              }
            }
            return differences;
          } else return null;
        })
        .filter(differenceObject => differenceObject && Object.keys(differenceObject).length > 1);

      if (flightsNew.length < flightsOld.length) {
        differencesInSameFlightNumbers.push({
          fluganzahl:
            'Die Fluganzahl hat sich von ' + flightsOld.length + ' auf ' + flightsNew.length + ' geändert.',
        });
      } else if (flightsNew.length > flightsOld.length) {
        differencesInSameFlightNumbers.push({
          fluganzahl:
            'Die Fluganzahl hat sich von ' + flightsOld.length + ' auf ' + flightsNew.length + ' geändert.',
        });
      }
      return differencesInSameFlightNumbers;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.passdaten-col) {
  width: 50rem;
}
:deep(.th-no-padding-top > th) {
  padding-top: 0px;
}
:deep(.th-no-padding-top > th) {
  border-bottom: 0px;
}
:deep(.th-no-padding-top > th > div) {
  color: #b5b5c3;
}
</style>
