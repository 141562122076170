<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header">
      <div class="card-title">
        <ActionButton
          class="mr-3"
          v-b-toggle.vorgaenge-collapse
          title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
          @click="toggleCollapse"
        />
        <h3 class="card-label">Vorgänge</h3>

        <b-form-checkbox
          class="mr-1"
          v-model="isBuchungFilterEnabled"
          switch
          style="display: flex; font-size: 14px"
        >
          Nur feste Buchungen anzeigen
        </b-form-checkbox>
      </div>
    </div>

    <b-collapse visible id="vorgaenge-collapse">
      <div class="card-body">
        <VorgangTable
          v-if="vorgaenge"
          :items="filteredVorgaenge"
          :isLoading="isLoading"
          :hiddenItemsCount="
            isBuchungFilterEnabled ? this.vorgaenge.length - this.filteredVorgaenge.length : null
          "
          :displayFieldsByKey="[
            'id',
            'reisezeitraum',
            'kunde',
            'zimmer',
            'npx',
            'agentur',
            'brutto',
            'isReleased',
            'stateSlot',
          ]"
          :dokumenteCheckRequired="dokumenteCheckRequired"
          :passdatenCheckRequired="passdatenCheckRequired"
          :reiseterminId="reiseterminObj.id"
          @update-single-item="updateItemInRow"
        >
          <template #stateSlot="row">
            <VorgangStateWidget :state="row.item.blankState" />
          </template>
        </VorgangTable>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER } from '@/core/vorgaenge/stores/vorgaenge.module';
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import ActionButton from '@/components/common/action-button.vue';

import { mapState } from 'vuex';

export default {
  components: { VorgangTable, VorgangStateWidget, ActionButton },
  props: {
    reiseterminObj: { type: Object },
    tabname: { type: String },
  },
  data() {
    return {
      isBuchungFilterEnabled: false,
      isCollapseOpen: true,
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.vorgaenge.isLoading,
      isInitialLoading: state => state.vorgaenge.isInitialLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
    }),
    dokumenteCheckRequired() {
      return this.reiseterminObj?.gueltigeKonfiguration?.dokumente;
    },
    passdatenCheckRequired() {
      return this.reiseterminObj?.gueltigeKonfiguration?.passdaten;
    },
    filteredVorgaenge() {
      return this.isBuchungFilterEnabled
        ? this.vorgaenge.filter(vorgang => vorgang.blankState === 'Buchung' || vorgang.isReleased)
        : this.vorgaenge;
    },
  },
  watch: {
    reiseterminObj() {
      this.loadData();
    },
  },
  mounted() {
    if (Array.isArray(this.vorgaenge) && this.vorgaenge.length <= 0 && !this.isLoading) {
      this.loadData();
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    loadData() {
      return this.$store.dispatch(GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER, {
        reiseterminId: this.reiseterminObj.id,
      });
    },
    async updateItemInRow() {
      this.$emit('update-single-item');
    },
  },
};
</script>
