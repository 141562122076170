<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <ActionButton
          class="mr-3"
          v-b-toggle.releasepruefung-collapse
          title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
          @click="toggleCollapse"
        />
        <h3 class="card-label">Releaseprüfung</h3>
      </div>
    </div>
    <b-collapse visible id="releasepruefung-collapse">
      <div class="card-body">
        <div v-if="reiseterminObj" class="gutter-b">
          <ReiseterminCardVorgaengeReleaseProcess
            :reiseterminObj="reiseterminObj"
            :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung || isInTransferplanung"
            :isInTransferplanung="isInTransferplanung"
            :gueltigeKonfiguration="reiseterminObj?.gueltigeKonfiguration"
            @releaseprocess-updated="$emit('releaseprocess-updated', $event)"
          ></ReiseterminCardVorgaengeReleaseProcess>
        </div>

        <div class="gutter-b" v-if="reiseterminObj">
          <ReiseterminViewCardReleaseProcessComponent
            :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung"
            :isInTransferplanung="isInTransferplanung"
            :reiseterminId="reiseterminObj.id"
            :reiseterminkuerzel="reiseterminObj.reiseterminkuerzel"
            @releaseprocess-updated="$emit('releaseprocess-updated', $event)"
          ></ReiseterminViewCardReleaseProcessComponent>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ActionButton from '@/components/common/action-button.vue';
import ReiseterminCardVorgaengeReleaseProcess from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-release-process.vue';
import ReiseterminViewCardReleaseProcessComponent from '@/components/produkte/reisetermine/reisetermin-card-release-process.vue';

export default {
  components: {
    ActionButton,
    ReiseterminCardVorgaengeReleaseProcess,
    ReiseterminViewCardReleaseProcessComponent,
  },

  props: {
    reiseterminObj: { type: Object },
  },

  data() {
    return { isCollapseOpen: true };
  },
  computed: {
    isReiseterminInReleasePruefung() {
      return this.reiseterminObj?.state === 'Aufgelegt';
    },
    isInTransferplanung() {
      return this.reiseterminObj?.state === 'Aufgelegt';
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
  },
};
</script>
